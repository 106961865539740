import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { BaseFindDto, FindResponse, UpdateResponse } from "@remar/shared/dist/api/baseApiService";
import { CreateUpdateLiveStream, LiveStream } from "@remar/shared/dist/models";

import { RootState } from "../../index";
import { liveStreamService } from "../../services/liveStream/liveStream.service";

interface LiveStreamState {
	isCreatingStream: boolean;
	isFetchingHistory: boolean;
	streamHistory: FindResponse<LiveStream> | null;
}

const initialState: LiveStreamState = {
	isCreatingStream: false,
	isFetchingHistory: false,
	streamHistory: null
};

export const createLiveStream = createAsyncThunk(
	"liveStream/createLiveStream",
	async ({ data, cb }: { data: CreateUpdateLiveStream; cb: (res: LiveStream) => void }, { rejectWithValue }) => {
		try {
			const res = await liveStreamService.create(data);
			cb && cb(res);
			return res;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateLiveStream = createAsyncThunk(
	"liveStream/updateLiveStream",
	async (
		{ id, data, cb }: { id: string; data: CreateUpdateLiveStream; cb: (res: UpdateResponse<LiveStream>) => void },
		{ rejectWithValue }
	) => {
		try {
			const res = await liveStreamService.update({ filters: { id }, data });
			cb && cb(res);
			return res;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const fetchLiveStreamHistory = createAsyncThunk(
	"liveStream/fetchLiveStreamHistory",
	async (options: BaseFindDto, { rejectWithValue }) => {
		return liveStreamService.find(options).catch(e => rejectWithValue(e));
	}
);

export const liveStreamSlice = createSlice({
	name: "liveStream",
	initialState,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(createLiveStream.pending, state => {
				state.isCreatingStream = true;
			})
			.addCase(createLiveStream.fulfilled, state => {
				state.isCreatingStream = false;
			})
			.addCase(createLiveStream.rejected, state => {
				state.isCreatingStream = false;
			})
			.addCase(fetchLiveStreamHistory.pending, state => {
				state.isFetchingHistory = true;
			})
			.addCase(fetchLiveStreamHistory.fulfilled, (state, action) => {
				state.isFetchingHistory = false;
				state.streamHistory = action.payload;
			})
			.addCase(fetchLiveStreamHistory.rejected, state => {
				state.isFetchingHistory = false;
			})
});

export const getLiveStreamFullState = (state: RootState): LiveStreamState => state.liveStream;

export default liveStreamSlice.reducer;
