import React from "react";

import { Box, useTheme } from "@material-ui/core";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { NotificationDto } from "store/services/notifications/dto";

type MarkerProps = {
	status: NotificationDto["status"];
};
const StatusMarker = ({ status }: MarkerProps) => {
	const theme = useTheme<IExtendedTheme>();
	return (
		<Box
			width={5}
			height={5}
			borderRadius="50%"
			marginRight={1}
			display={"inline-block"}
			bgcolor={status === "delivered" ? theme.palette.colors.success[500] : theme.palette.colors.warning[500]}
		/>
	);
};

export default StatusMarker;
