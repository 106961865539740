import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseFindDto } from "@remar/shared/dist/api/baseApiService";

import { BannerNotification, IBaseState, NotificationTypeId } from "@remar/shared/dist/models";

import { performFileUpload } from "@remar/shared/dist/utils/serviceUtils/uploaders";
import { omit } from "lodash";
import { RootState } from "store";

import { notificationsService } from "store/services/notifications/notifications.service";

import { IFormValues } from "modules/App/View/Container/Notifications/CreateNotification/const";

import { NotificationDto } from "../../services/notifications/dto";
import { emit } from "../notifications/notifications.slice";

const SLICE_NAME = "banner";

interface BannerState extends IBaseState {
	banners: BannerNotification[];
	totalItems: number;
	isEditingBanner: boolean;
	conflict: NotificationTypeId | null;
	notificationItems: NotificationDto[];
	notificationPage: number;
	notificationPerPage: number;
	notificationTotalItems: number;
	deleteLoading: boolean;
	isCreatingNotification: boolean;
}

const initialState: BannerState = {
	isLoading: false,
	error: "",
	banners: [],
	totalItems: 0,
	isEditingBanner: false,
	notificationItems: [],
	notificationPage: 1,
	notificationPerPage: 10,
	notificationTotalItems: 0,
	conflict: null,
	deleteLoading: false,
	isCreatingNotification: false
};

export const bannerSlice = createSlice({
	name: SLICE_NAME,
	initialState,
	reducers: {
		clearConflict: state => {
			state.conflict = null;
		},
		cleanError: state => {
			state.error = "";
		}
	},
	extraReducers: builder => {
		builder
			.addCase(fetchBanners.pending, state => {
				state.isLoading = true;
			})
			.addCase(fetchBanners.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.banners = payload.items;
				state.totalItems = payload.totalItems || 0;
			})
			.addCase(fetchBanners.rejected, (state, { payload: { message } }) => {
				state.isLoading = false;
				state.error = message;
			})
			.addCase(updateBanner.pending, state => {
				state.isEditingBanner = true;
			})
			.addCase(updateBanner.fulfilled, state => {
				state.isEditingBanner = false;
			})
			.addCase(updateBanner.rejected, state => {
				state.isEditingBanner = false;
			})
			.addCase(fetchNotifications.pending, state => {
				state.isLoading = true;
			})
			.addCase(fetchNotifications.fulfilled, (state, { payload: { items, page, perPage, totalItems } }) => {
				state.notificationItems = items;
				state.notificationPage = page;
				state.notificationPerPage = perPage;
				state.notificationTotalItems = totalItems || 0;
				state.isLoading = false;
			})
			.addCase(fetchNotifications.rejected, state => {
				state.isLoading = false;
			})
			.addCase(createUpdateNotification.pending, state => {
				state.isCreatingNotification = true;
			})
			.addCase(createUpdateNotification.fulfilled, state => {
				state.isCreatingNotification = false;
			})
			.addCase(createUpdateNotification.rejected, (state, { payload /*meta: { arg }*/ }) => {
				state.error = payload.message;
				//todo: handle conflicts
				// state.conflict = arg.notificationTypeId;
				state.isCreatingNotification = false;
			})
			.addCase(deleteNotification.pending, state => {
				state.deleteLoading = true;
			})
			.addCase(deleteNotification.fulfilled, state => {
				state.deleteLoading = false;
			})
			.addCase(deleteNotification.rejected, state => {
				state.deleteLoading = false;
			});
	}
});

export const fetchBanners = createAsyncThunk(
	`${SLICE_NAME}/fetchBanners`,
	async ({ page, perPage, searchKeyword = "", filters }: BaseFindDto, { rejectWithValue }) => {
		try {
			return await notificationsService.find({
				page,
				perPage,
				searchKeyword,
				filters,
				findAll: true,
				orderBy: { createdAt: "DESC" },
				include: ["notificationType", "notificationAudienceType", "coupon"]
			});
		} catch (error) {
			return rejectWithValue(error as Error);
		}
	}
);

export const updateBanner = createAsyncThunk(
	`${SLICE_NAME}/updateBanner`,
	async ({ id, data, cb }: { id: number; data; cb: () => void }, { dispatch, rejectWithValue }) => {
		try {
			const res = await notificationsService.update({
				data: data,
				filters: { id }
			});
			cb();
			dispatch(emit({ message: "Banner has been updated successfully", color: "success" }));
			return res;
		} catch (error) {
			return rejectWithValue(error as Error);
		}
	}
);

export const fetchNotifications = createAsyncThunk(
	"manageNotifications/fetchNotifications",
	async ({ page, perPage, searchKeyword = "", filters }: BaseFindDto, { rejectWithValue }) => {
		return await notificationsService
			.find({
				page,
				perPage,
				searchKeyword,
				filters,
				orderBy: { createdAt: "DESC" },
				include: ["notificationType", "notificationAudienceType"]
			})
			.catch(rejectWithValue);
	}
);

export const createUpdateNotification = createAsyncThunk(
	"manageNotifications/createUpdateNotification",
	async (notification: IFormValues, { rejectWithValue, dispatch }) => {
		const data = {
			...omit(notification, [
				"notificationAudienceTypeId",
				"notificationAudienceType",
				"notificationType",
				"addDatePeriod",
				"buttonAction",
				"buttonUrl",
				"buttonSuccessText",
				"buttonCancelText",
				"sendAtDate",
				"expiresAtDate"
			]),
			notificationAudienceTypeId: parseInt(notification.notificationAudienceTypeId, 10),
			sendAt: notification.sendAtDate ? notification.sendAtDate.toISOString() : undefined,
			expiresAt: notification.expiresAtDate ? notification.expiresAtDate.toISOString() : undefined,
			data: {
				primaryButton: {
					text: notification.buttonSuccessText,
					action: notification.buttonAction,
					url: notification.buttonUrl
				},
				secondaryButton:
					notification.notificationTypeId === NotificationTypeId.AdminGeneratedPush
						? {
								text: notification.buttonCancelText,
								action: "dismiss" // todo: BE bug
						  }
						: undefined
			}
		};
		const result = notification.id
			? await notificationsService.update({ data, filters: { id: notification.id } }).catch(rejectWithValue)
			: await notificationsService.create(data).catch(rejectWithValue);
		dispatch(
			fetchNotifications({
				page: 1,
				perPage: 10,
				filters: {
					notificationTypeId: [
						NotificationTypeId.AdminGeneratedBanner,
						NotificationTypeId.AdminGeneratedPush,
						NotificationTypeId.AdminGeneratedAnnouncement
					]
				}
			})
		);
		return result;
	}
);

export const deleteNotification = createAsyncThunk(
	"manageNotifications/deleteNotification",
	async (id: number, { dispatch, rejectWithValue }) => {
		await notificationsService
			.delete({ filters: { id } })
			.then(() => {
				dispatch(emit({ message: "Notification has been successfully deleted", color: "success" }));
				dispatch(
					fetchNotifications({
						page: 1,
						perPage: 10,
						filters: {
							notificationTypeId: [
								NotificationTypeId.AdminGeneratedBanner,
								NotificationTypeId.AdminGeneratedPush,
								NotificationTypeId.AdminGeneratedAnnouncement
							]
						}
					})
				);
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
				return rejectWithValue(e as Error);
			});
	}
);

export const uploadAttachment = createAsyncThunk(
	"manageNotifications/uploadAttachment",
	async (data: {
		file: Partial<File>;
		options: {
			onError: () => void;
			onProgress: ({}: { loaded: number; total: number }) => void;
			onUploaded: () => void;
		};
	}) => {
		const { file, options } = data;
		const { key } = await performFileUpload({ file }, options);
		return key;
	}
);

export const selectBannersFullState = (state: RootState): BannerState => state.banners;

export const { clearConflict, cleanError } = bannerSlice.actions;
export default bannerSlice.reducer;
