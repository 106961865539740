import React, { useEffect } from "react";

import { Box, Checkbox, Divider, FormControlLabel, TextField, Typography } from "@material-ui/core";

import { AutocompleteFilter } from "@remar/shared/dist/components/AutocompleteFilter";
import Button from "@remar/shared/dist/components/Button";
import { Wrapper } from "@remar/shared/dist/layouts";

import { CreateUpdateLiveStream } from "@remar/shared/dist/models";
import { ContentAccessTypes } from "@remar/shared/dist/models/courseChapter.model";
import { useFormik } from "formik";
import { Location } from "institutions/src/store/services";
import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";
import { createLiveStream, getLiveStreamFullState } from "store/features/LiveStream/LiveStream.slice";
import { fetchLocations, selectManageLocationsFullState } from "store/features/ManageLocations/manageLocations.slice";

import * as Yup from "yup";

import { routes } from "core/constants";
import { InputField } from "modules/Components/InputField";

import { CreateStreamWrapper } from "./styles";

import { StyledLocationIcon } from "../ManageStudents/styles";

const breadcrumb = [
	{ title: "Live Stream", key: 0, link: routes.stream.getPath() },
	{ title: "Create Live Stream", key: 1 }
];

const validationSchema = Yup.object({
	name: Yup.string().required("Required"),
	description: Yup.string(),
	allAudience: Yup.boolean(),
	paidAudience: Yup.boolean(),
	trialAudience: Yup.boolean(),
	audienceTest: Yup.mixed().test("audience_test", "At least one audience type must be selected", function () {
		const { allAudience, paidAudience, trialAudience } = this.parent;
		return allAudience || paidAudience || trialAudience;
	}),
	allLocations: Yup.boolean(),
	selectedLocations: Yup.array().of(
		Yup.object().shape({
			id: Yup.number(),
			name: Yup.string()
		})
	),
	locationTest: Yup.mixed().test("location_test", "At least one location type must be selected", function () {
		const { allLocations, selectedLocations } = this.parent;
		return allLocations || selectedLocations?.length > 0;
	})
});

const LOCATION_PER_PAGE = 20;
const initVals = {
	name: "",
	description: ""
} as CreateUpdateLiveStream;

const StartStream = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { locations, page: locationPage } = useSelector(selectManageLocationsFullState);
	const { isCreatingStream } = useSelector(getLiveStreamFullState);

	useEffect(() => {
		dispatch(fetchLocations({ perPage: 10, page: 1, searchKeyword: "", filters: {} }));
	}, []);

	const { values, handleChange, handleBlur, errors, touched, setFieldValue, handleSubmit, dirty, isValid } = useFormik({
		initialValues: initVals,
		validationSchema,
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: v => {
			const { name, description, allAudience, paidAudience, allLocations, selectedLocations } = v;
			const contentAccessTypeId = allAudience
				? ContentAccessTypes.Mixed
				: paidAudience
				? ContentAccessTypes.Paid
				: ContentAccessTypes.Trial;
			const body = {
				name,
				description,
				startTime: new Date().toISOString(),
				contentAccessTypeId,
				allLocations
			};
			if (selectedLocations.length > 0) {
				body["allLocations"] = false;
				body["locationIds"] = (selectedLocations as Location[]).map(location => ({ value: location.id }));
			}

			dispatch(
				createLiveStream({
					data: body,
					cb: stream => {
						history.push(`${routes.stream.join.getPath()}/${stream.id}/${stream.streamId}`);
					}
				})
			);
		}
	});
	const neverTouched = Object.keys(touched).length === 0;

	const { name, description, selectedLocations, allAudience, paidAudience, trialAudience, allLocations } = values;
	return (
		<form onSubmit={handleSubmit}>
			<Wrapper
				heading="Create Stream"
				breadcrumb={breadcrumb}
				actions={
					<Box display="flex" gridGap={5}>
						<Button variant="filled" color="secondary">
							Cancel
						</Button>
						<Button
							variant={"filled"}
							color={"primary"}
							type="submit"
							disabled={isCreatingStream || neverTouched || (dirty && !isValid)}
							loading={isCreatingStream}
						>
							Go Live Now
						</Button>
					</Box>
				}
			>
				<CreateStreamWrapper>
					<Box className={"headerWrapper"}>
						<Typography className={"header"} color={"textSecondary"}>
							Live Stream Configuration
						</Typography>
					</Box>
					<Box className={"configWrapper"}>
						<Box width="70%">
							<InputField
								title="Live Stream Name"
								inputComponent={
									<TextField
										fullWidth
										hiddenLabel
										InputProps={{ style: { color: "primary" }, disableUnderline: true }}
										inputProps={{ maxLength: 140 }}
										color="primary"
										id="name"
										name="name"
										onChange={handleChange}
										onBlur={handleBlur}
										variant="filled"
										size="small"
										error={!!errors.name && touched.name}
										helperText={!!errors.name && touched.name}
										placeholder="Name"
										value={name}
									/>
								}
							/>
							<InputField
								title="Live Description (Optional)"
								inputComponent={
									<TextField
										fullWidth
										hiddenLabel
										InputProps={{ style: { color: "primary" }, disableUnderline: true }}
										inputProps={{ maxLength: 140 }}
										color="primary"
										id="description"
										name="description"
										onChange={handleChange}
										onBlur={handleBlur}
										variant="filled"
										size="small"
										error={!!errors.description && touched.description}
										helperText={!!errors.description && touched.description}
										placeholder="Description"
										value={description}
									/>
								}
							/>{" "}
							<InputField
								title="Audience"
								inputComponent={
									<Box display="flex">
										<FormControlLabel
											label="All"
											control={
												<Checkbox
													checked={!!allAudience}
													name={"allAudience"}
													onChange={e => {
														handleChange(e);
														setFieldValue("paidAudience", e.target.checked);
														setFieldValue("trialAudience", e.target.checked);
													}}
													color="primary"
												/>
											}
										/>
										<Divider orientation="vertical" flexItem className={"divider"} />
										<FormControlLabel
											label="Paid"
											control={
												<Checkbox
													checked={!!paidAudience}
													name={"paidAudience"}
													onChange={e => {
														handleChange(e);
														if (!e.target.checked) {
															setFieldValue("allAudience", false);
														}
													}}
													color="primary"
												/>
											}
										/>
										<FormControlLabel
											label="Trial"
											control={
												<Checkbox
													name={"trialAudience"}
													checked={!!trialAudience}
													onChange={e => {
														handleChange(e);
														if (!e.target.checked) {
															setFieldValue("allAudience", false);
														}
													}}
													color="primary"
												/>
											}
										/>
										<FormControlLabel
											label="All Locations"
											control={
												<Checkbox
													name={"allLocations"}
													checked={allLocations}
													onChange={e => {
														handleChange(e);
														if (e.target.checked) {
															setFieldValue("selectedLocations", []);
														}
													}}
													color="primary"
												/>
											}
										/>
										<AutocompleteFilter
											options={locations}
											value={selectedLocations || []}
											setValue={v => {
												setFieldValue("selectedLocations", v);
												if (v.length > 0) {
													setFieldValue("allLocations", false);
												}
											}}
											filterName={"Locations"}
											filterIcon={<StyledLocationIcon />}
											noOptionsText={"No Location Selected"}
											loadMoreResults={() => {
												dispatch(
													fetchLocations({
														perPage: LOCATION_PER_PAGE,
														page: locationPage + 1,
														searchKeyword: "",
														filters: {},
														infinite: true
													})
												);
											}}
											onTextInputChange={text => {
												dispatch(
													fetchLocations({
														perPage: LOCATION_PER_PAGE,
														page: 1,
														searchKeyword: text,
														filters: {}
													})
												);
											}}
										/>
									</Box>
								}
							/>
						</Box>
					</Box>
				</CreateStreamWrapper>
			</Wrapper>
		</form>
	);
};

export default StartStream;
