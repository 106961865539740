import React, { useEffect, useRef, useState } from "react";

import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";
import { Box, Card, Grid, TextField } from "@material-ui/core";
import FileCopy from "@material-ui/icons/FileCopy";
import Button from "@remar/shared/dist/components/Button";

import { Wrapper } from "@remar/shared/dist/layouts";
import { differenceInSeconds } from "date-fns";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { updateLiveStream } from "store/features/LiveStream/LiveStream.slice";
import { emit } from "store/features/notifications/notifications.slice";
import videojs from "video.js";

import "video.js/dist/video-js.min.css";

import { GLOBAL_CONSTANTS, routes } from "core/constants";

import { VideoContainer } from "./styles";

const breadcrumb = [
	{ title: "Live Stream", key: 0, link: routes.stream.getPath() },
	{ title: "Create Live Stream", key: 1, link: routes.stream.createNew.getPath() },
	{ title: "Live Streaming", key: 2 }
];

enum WebRTCAdaptorStatus {
	INITIALIZED = "initialized",
	STATE_CHANGED = "ice_connection_state_changed",
	PUBLISH_STARTED = "publish_started",
	PUBLISH_FINISHED = "publish_finished",
	CLOSED = "closed",
	RECONNECTING = "reconnection_attempt_for_publisher",
	UPDATED_STATS = "updated_stats",
	NUMBER_VIEWERS = "number_of_viewers",
	PLAY_STARTED = "play_started",
	PLAY_FINISHED = "play_finished",
	STREAM_INFO = "stream_information"
}

const StreamVideo = () => {
	const dispatch = useDispatch();
	const videoRef = useRef<HTMLVideoElement>(null);
	const player = useRef<videojs.Player>(null);
	const adaptorRef = useRef<typeof WebRTCAdaptor>(null);
	const { id, sId } = useParams<{ id: string; sId: string }>();
	const [isLoading, setIsLoading] = useState(true);
	const [isOnline, setIsOnline] = useState(false);

	useEffect(() => {
		if (!sId) return;

		adaptorRef.current = new WebRTCAdaptor({
			websocket_url: `${GLOBAL_CONSTANTS.ANT_MEDIA_SERVER_URL}/websocket`,
			mediaConstraints: {
				video: true,
				audio: true
			},
			peerconnection_config: {
				iceServers: JSON.parse(GLOBAL_CONSTANTS.ICE_SERVERS as string)
			},
			sdp_constraints: {
				OfferToReceiveAudio: false,
				OfferToReceiveVideo: false
			},
			localVideoId: "video-player-stream",
			bandwidth: 900, // default is 900 kbps, string can be 'unlimited'
			dataChannelEnabled: false, // enable or disable data channel
			debug: true,
			callback: (info, obj) => {
				console.log("WebRTC Callback: ", info, obj);
				switch (info) {
					case WebRTCAdaptorStatus.INITIALIZED: {
						console.log("WebRTCAdaptor initialized");
						publishStream(sId);
						break;
					}
					case WebRTCAdaptorStatus.STATE_CHANGED: {
						if (obj.state === "checking") {
							setIsLoading(true);
						}
						if (obj.state === "connected") {
							setIsLoading(false);
							setIsOnline(true);
						}
						break;
					}
					case WebRTCAdaptorStatus.PUBLISH_STARTED: {
						console.log("Publishing started");
						dispatch(emit({ message: "Stream has started!", color: "success" }));
						break;
					}
					case WebRTCAdaptorStatus.PUBLISH_FINISHED: {
						console.log("Publishing stopped");
						break;
					}
					case WebRTCAdaptorStatus.CLOSED: {
						console.log(`Connection closed for streamId: ${sId}`);
						break;
					}
					case WebRTCAdaptorStatus.RECONNECTING: {
						console.log("trying to reconnect...");
						break;
					}
					case WebRTCAdaptorStatus.UPDATED_STATS: {
						console.log(obj);
						console.log(`Stream ID: ${obj.streamId}, Viewers: ${obj.webRTCViewerCount}`);
						break;
					}
					case WebRTCAdaptorStatus.NUMBER_VIEWERS: {
						console.log(`Stream ID: ${obj.streamId}, Viewers: ${obj.viewerCount}`);
						break;
					}
					case WebRTCAdaptorStatus.PLAY_STARTED: {
						console.log("Playing started");
						break;
					}
					case WebRTCAdaptorStatus.PLAY_FINISHED: {
						console.log("Playing stopped");
						break;
					}
					case WebRTCAdaptorStatus.STREAM_INFO: {
						console.log(obj);
					}
				}
			},
			callbackError: (error, message) => {
				console.error(`WebRtcError: ${message}}`, error);
			}
		});

		return () => {
			if (adaptorRef.current) {
				adaptorRef.current.close();
			}
		};
	}, [sId]);

	useEffect(() => {
		if (player.current) {
			isLoading ? player.current.loadingSpinner.show() : player.current.loadingSpinner.hide();
		}
	}, [isLoading]);

	useEffect(() => {
		if (videoRef.current) {
			player.current = player.current ?? videojs(videoRef.current);

			player.current.on("error", (_, errors) => {
				console.error("Video.js encountered an error", errors);
			});

			player.current.on("ant-error", (_, errors) => console.error("Error during WebRTC playback:", errors));
			player.current.on("play", () => {
				console.log("Player played");
				localStorage.setItem(`stream-start-${sId}`, "" + new Date().getTime());
			});
			player.current.on("pause", () => {
				console.log("Player pause");
				handleUpdateStream(sId, localStorage.getItem(`stream-start-${sId}`) as string);
			});
		}

		return () => {
			if (player.current) {
				player.current.dispose();
			}
		};
	}, [videoRef, sId]);

	const publishStream = (sId: string) => {
		console.log("Publishing Stream", adaptorRef.current, sId);
		player.current.play();
		adaptorRef.current && adaptorRef.current.publish(sId);
	};

	const leaveStream = sId => {
		console.log("leaveStream");
		if (adaptorRef.current) {
			getStreamStats(sId);
			adaptorRef.current.stop(sId);
			player.current.pause();
			setIsOnline(false);
		}
	};

	const getStreamStats = sId => {
		console.log("Getting streams stats", sId);
		adaptorRef.current && adaptorRef.current.getStats(sId);
	};

	const handleStopStream = () => {
		leaveStream(sId);
	};

	const handleUpdateStream = (sId: string, startTime: string): void => {
		const timeNow = new Date().getTime();
		const durationInSeconds = differenceInSeconds(timeNow, +startTime);

		dispatch(
			updateLiveStream({
				id,
				data: { durationInSeconds, hasEnded: true },
				cb: () => {
					localStorage.removeItem(`stream-start-${sId}`);
				}
			})
		);
	};

	return (
		<Wrapper
			heading="Stream"
			breadcrumb={breadcrumb}
			actions={
				<Box>
					{isOnline && (
						<Button
							variant={"filled"}
							color={"danger"}
							onClick={handleStopStream}
							disabled={isLoading}
							loading={isLoading}
						>
							{"Stop Stream"}
						</Button>
					)}
				</Box>
			}
		>
			<Grid container spacing={1}>
				<Grid item xs={9}>
					<VideoContainer>
						<video ref={videoRef} id="video-player-stream" autoPlay className={"video-js vjs-default-skin"} controls />
					</VideoContainer>
				</Grid>
				<Grid item xs={3}>
					<Card>
						<TextField
							variant="outlined"
							fullWidth
							value={`${GLOBAL_CONSTANTS.ANT_MEDIA_SERVER_URL}/player.html?id=${sId}`
								.replace(":5080", ":5443")
								.replace("ws://", "https://")}
							InputProps={{
								readOnly: true,
								endAdornment: (
									<Box ml={2}>
										<FileCopy
											style={{ cursor: "pointer" }}
											onClick={async () => {
												await navigator.clipboard.writeText(
													`${GLOBAL_CONSTANTS.ANT_MEDIA_SERVER_URL}/player.html?id=${sId}`
														.replace(":5080", ":5443")
														.replace("ws://", "https://")
												);
												dispatch(
													emit({
														message: "Stream link copied to clipboard",
														color: "success"
													})
												);
											}}
										/>
									</Box>
								)
							}}
						/>
					</Card>
				</Grid>
			</Grid>
		</Wrapper>
	);
};

export default StreamVideo;
