import { Box, Card, IconButton, lighten } from "@material-ui/core";
import styled from "styled-components";

export const StyledIconButton = styled(IconButton)<{ muted: boolean }>`
	background: ${({ muted, theme }) => (muted ? theme.palette.colors.success[500] : theme.palette.colors.danger[500])};
`;

export const CreateStreamWrapper = styled(Card)`
	padding: 0;
	.headerWrapper {
		padding: 15px;
		background-color: ${({ theme }) => lighten(theme.palette.background.paper, 0.1)};
		.header {
			font-family: var(--openSans);
			font-size: 15px;
			font-weight: 600;
		}
	}
	.configWrapper {
		padding: 20px 40px;
		display: flex;
		flex-direction: column;
		.configRow {
			display: flex;
		}
	}
	.divider {
		background-color: ${({ theme }) => theme.palette.colors.basic[600]};
		margin-right: 10px;
	}
`;

export const VideoContainer = styled(Box)`
	width: 100%; /* Parent div takes full width */

	.video-js {
		width: 100% !important; /* Video takes full width of parent */
		height: 500px !important; /* Set your desired height */
	}
`;
