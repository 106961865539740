import React, { useEffect, useMemo } from "react";

import { Box } from "@material-ui/core";
import { FindResponse } from "@remar/shared/dist/api/baseApiService";
import Button from "@remar/shared/dist/components/Button";
import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import { ColumnHeader, StyledCellText, StyledCellWrapper } from "@remar/shared/dist/components/Table/styles";
import { EmptyState } from "@remar/shared/dist/layouts";
import WithTableContentLayout from "@remar/shared/dist/layouts/TableContentLayout";

import { ContentAccessTypes, LiveStream } from "@remar/shared/dist/models";
import { convertDuration } from "@remar/shared/dist/utils/convertMinsToHrsMins";
import { formatDate, formatTime } from "@remar/shared/dist/utils/myAccountUtils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchLiveStreamHistory, getLiveStreamFullState } from "store/features/LiveStream/LiveStream.slice";

import CheckPermissions from "core/CheckPermissions";
import { GLOBAL_CONSTANTS, routes } from "core/constants";

const HISTORY_PER_PAGE = 10;
const StreamHistory = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { isFetchingHistory, streamHistory } = useSelector(getLiveStreamFullState);

	useEffect(() => {
		dispatch(fetchLiveStreamHistory({ page: 1, perPage: HISTORY_PER_PAGE, include: ["allowedLocations"] }));
	}, []);

	const { page, perPage, items, totalItems } = useMemo(() => {
		if (!streamHistory) {
			return { items: [] } as unknown as FindResponse<LiveStream>;
		}
		const { page, perPage, items, totalItems } = streamHistory;
		return { page, perPage, items, totalItems };
	}, [streamHistory]);

	const getAudience = (type: number, allLocations: boolean, allowedLocations) => {
		let audience = "";
		switch (type) {
			case ContentAccessTypes.Mixed: {
				audience = "Paid, Trial";
				break;
			}
			case ContentAccessTypes.Paid: {
				audience = "Paid";
				break;
			}
			case ContentAccessTypes.Trial: {
				audience = "Trial";
				break;
			}
		}
		if (allowedLocations.length > 0) {
			audience += `, Location (${allowedLocations.length})`;
		} else if (allLocations) {
			audience += ", All Locations";
		}
		return audience;
	};

	const navigateToStream = (sId: string) => {
		window.open(
			`${GLOBAL_CONSTANTS.ANT_MEDIA_SERVER_URL}/player.html?id=${sId}`
				.replace(":5080", ":5443")
				.replace("ws://", "https://"),
			"_blank"
		);
	};

	const tableColumns: Array<IColumn<LiveStream>> = useMemo(
		() => [
			{
				alignment: "left",
				label: <ColumnHeader>Name & Description</ColumnHeader>,
				Cell: ({ rowData: { name, description, streamId } }) => (
					<StyledCellWrapper>
						<Box display="flex" flexDirection="column" onClick={() => navigateToStream(streamId)}>
							<StyledCellText style={{ width: "100%", marginBottom: "5px" }}>{name}</StyledCellText>
							<StyledCellText color={"textSecondary"} style={{ width: "100%" }}>
								{description}
							</StyledCellText>
						</Box>
					</StyledCellWrapper>
				),
				dataKey: "name_description"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Date & Time</ColumnHeader>,
				Cell: ({ rowData: { startTime } }) => (
					<StyledCellWrapper center>
						<Box display="flex" flexDirection="column">
							<StyledCellText>{formatDate(new Date(startTime))}</StyledCellText>
							<StyledCellText>{formatTime(new Date(startTime))}</StyledCellText>
						</Box>
					</StyledCellWrapper>
				),
				dataKey: "date_time"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Duration</ColumnHeader>,
				Cell: ({ rowData: { durationInSeconds } }) => (
					<StyledCellWrapper center>
						<Box display="flex" flexDirection="column">
							<StyledCellText>{convertDuration(durationInSeconds || 0)}</StyledCellText>
						</Box>
					</StyledCellWrapper>
				),
				dataKey: "duration"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Audience</ColumnHeader>,
				Cell: ({ rowData: { contentAccessTypeId, allLocations, allowedLocations } }) => (
					<StyledCellWrapper center>
						<Box display="flex" flexDirection="column">
							<StyledCellText>{getAudience(contentAccessTypeId, allLocations, allowedLocations)}</StyledCellText>
						</Box>
					</StyledCellWrapper>
				),
				dataKey: "audience"
			}
		],
		[items]
	);

	return (
		<WithTableContentLayout
			heading="Live Stream"
			isLoading={isFetchingHistory}
			actions={
				<CheckPermissions>
					<Button color={"primary"} variant={"filled"} onClick={() => history.push(routes.stream.createNew.getPath())}>
						Create Live Stream
					</Button>
				</CheckPermissions>
			}
			emptyState={<EmptyState description="No Steams found" />}
			onChangePage={page => {
				dispatch(fetchLiveStreamHistory({ page, perPage: HISTORY_PER_PAGE, include: ["allowedLocations"] }));
			}}
			totalItems={totalItems!}
			totalEntities={totalItems}
			tableTitle="Streams"
			perPage={perPage}
			page={page}
			tableColumns={tableColumns}
			data={items}
		/>
	);
};

export default StreamHistory;
