import React from "react";

import { ScrollToTop } from "@remar/shared/dist/components/ScrollToTop";
import {
	AddEditGift,
	AddQuestion,
	Books,
	CATAvailability,
	Chapters,
	CommunityPool,
	CommunityPoolManageSubjects,
	Content,
	Coupons,
	CourseAddEdit,
	CreateLesson,
	FileVault,
	FileVaultSubFolders,
	FileVaultSubFoldersFiles,
	Gifts,
	Home,
	IndividualCourse,
	Invite,
	ManageAdmins,
	ManageLocations,
	ManageNotifications,
	ManagePackage,
	ManageSchools,
	ManageStudents,
	ManageSubjects,
	PackageOverview,
	QuestionBank,
	Reports,
	Settings,
	SignIn,
	StartStream,
	StreamHistory,
	StreamVideo,
	StudentAccount,
	StudentReport,
	SubscriptionCancellations,
	Tags
} from "Pages";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { routes } from "core/constants";
import { LessonDetails } from "modules/App/View";
import DiscussionBoard from "modules/App/View/Container/DiscussionBoard";
import FileVaultPersonalStorageFiles from "modules/App/View/Container/FileVault/FileVaultPersonalStorageFiles";
import CourseCompletionDetails from "modules/App/View/Container/ManageStudents/CourseCompletionDetails";
import { AccountActivity, StudentTestResults } from "modules/App/View/Container/StudentAccount";
import Videos from "modules/App/View/Container/Videos";
import EditVideo from "modules/App/View/Container/Videos/EditVideo";
import { BusinessBannersList } from "modules/Components/BusinessBanners";
import BaseLayout from "modules/Layout/BaseLayout";

import AuthRoute from "./AuthRoute";
import Notifications from "./Notifications";

const PREFIX = "/";

{
	/* TODO: add Not found page*/
}
const Routes = () => {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Route path={PREFIX}>
				<Switch>
					<AuthRoute
						exact
						key={routes.invitation.getElementKey()}
						path={`${routes.invitation.getPath()}/:invitationId`}
						component={Invite}
					/>
					<AuthRoute exact key={routes.signIn.getElementKey()} path={routes.signIn.getPath()} component={SignIn} />
					<BaseLayout>
						{" "}
						<Switch>
							<AuthRoute exact key={"/"} path={"/"} component={Home} />
							<AuthRoute exact key={routes.course.getElementKey()} path={routes.course.getPath()} component={Content} />
							<AuthRoute
								exact
								key={`${routes.course.getElementKey()}-content`}
								path={`${routes.course.getPath()}/:courseId`}
								component={IndividualCourse}
							/>
							<AuthRoute
								exact
								key={`${routes.courseAddEdit.getElementKey()}-add`}
								path={`${routes.courseAddEdit.getPath()}`}
								component={CourseAddEdit}
							/>
							<AuthRoute
								exact
								key={`${routes.courseAddEdit.getElementKey()}-edit`}
								path={`${routes.courseAddEdit.getPath()}/:id`}
								component={CourseAddEdit}
							/>
							<AuthRoute
								exact
								key={`${routes.course.getElementKey()}chapters`}
								path={`${routes.course.getPath()}/:courseId/chapters/:id`}
								component={Chapters}
							/>
							<AuthRoute
								exact
								key={routes.questionbank.getElementKey()}
								path={routes.questionbank.getPath()}
								component={QuestionBank}
							/>
							<AuthRoute
								exact
								key={`${routes.questionbank.getElementKey()}-add`}
								path={`${routes.questionbank.getPath()}/add-new-question`}
								component={AddQuestion}
							/>
							<AuthRoute
								exact
								key={`${routes.questionbank.getElementKey()}-edit`}
								path={`${routes.questionbank.getPath()}/:questionId`}
								component={AddQuestion}
							/>
							<AuthRoute
								exact
								key={routes.manageSubjects.getElementKey()}
								path={routes.manageSubjects.getPath()}
								component={ManageSubjects}
							/>
							<AuthRoute
								exact
								key={routes.createLesson.getElementKey()}
								path={`${routes.createLesson.getPath()}/:lessonId?`}
								component={CreateLesson}
							/>
							<AuthRoute
								exact
								key={routes.settings.getElementKey()}
								path={routes.settings.getPath()}
								component={Settings}
							/>
							<AuthRoute
								exact
								key={routes.manageStudents.getElementKey()}
								path={routes.manageStudents.getPath()}
								component={ManageStudents}
							/>
							<AuthRoute
								exact
								key={routes.manageStudents.subscriptionCancellations.getElementKey()}
								path={routes.manageStudents.subscriptionCancellations.getPath()}
								component={SubscriptionCancellations}
							/>
							<AuthRoute
								exact
								key={routes.manageStudents.getElementKey()}
								path={`${routes.manageStudents.getPath()}/:id`}
								component={StudentAccount}
							/>
							<AuthRoute
								exact
								key={routes.manageStudents.getElementKey()}
								path={`${routes.manageStudents.getPath()}/:id/courseCompletion`}
								component={CourseCompletionDetails}
							/>
							<AuthRoute
								exact
								key={routes.manageStudents.getElementKey()}
								path={`${routes.manageStudents.getPath()}/:id/reports`}
								component={StudentReport}
							/>
							<AuthRoute
								exact
								key={routes.manageStudents.getElementKey()}
								path={`${routes.manageStudents.getPath()}/:id/test-results`}
								component={StudentTestResults}
							/>
							<AuthRoute
								exact
								key={routes.newReports.getElementKey()}
								path={`${routes.manageStudents.getPath()}/:id/activity`}
								component={AccountActivity}
							/>
							<AuthRoute
								exact
								key={routes.catAvailability.getElementKey()}
								path={routes.catAvailability.getPath()}
								component={CATAvailability}
							/>
							<AuthRoute
								exact
								key={routes.manageTutorial.getElementKey()}
								path={routes.manageTutorial.getPath()}
								component={CreateLesson}
							/>
							<AuthRoute
								exact
								key={routes.manageSchools.getElementKey()}
								path={routes.manageSchools.getPath()}
								component={ManageSchools}
							/>
							<AuthRoute
								exact
								key={routes.manageLocations.getElementKey()}
								path={routes.manageLocations.getPath()}
								component={ManageLocations}
							/>
							<AuthRoute
								exact
								key={`${routes.manageLocations.getElementKey()}-package-overview`}
								path={`${routes.manageLocations.getPath()}/package-overview`}
								component={PackageOverview}
							/>
							<AuthRoute
								exact
								key={`${routes.manageLocations.getElementKey()}-package-overview/manage-package`}
								path={`${routes.manageLocations.getPath()}/package-overview/manage-package/:courseId/:locationPackageTypeId/:action`}
								component={ManagePackage}
							/>
							<AuthRoute
								exact
								key={routes.fileVault.getElementKey()}
								path={routes.fileVault.getPath()}
								component={FileVault}
							/>
							<AuthRoute
								exact
								key={`${routes.fileVault.getElementKey()}-content`}
								path={`${routes.fileVault.getPath()}/:folderId`}
								component={FileVaultSubFolders}
							/>
							<AuthRoute
								exact
								key={`${routes.fileVault.getElementKey()}-personal`}
								path={`${routes.fileVault.getPath()}/personal/:folderId`}
								component={FileVaultPersonalStorageFiles}
							/>
							<AuthRoute
								exact
								key={`${routes.fileVault.getElementKey()}-subfolder`}
								path={`${routes.fileVault.getPath()}/:folderId/subFolders/:subFolderId/files`}
								component={FileVaultSubFoldersFiles}
							/>
							<AuthRoute
								exact
								key={`${routes.communityPool.getElementKey()}`}
								path={`${routes.communityPool.getPath()}`}
								component={CommunityPool}
							/>
							<AuthRoute
								exact
								key={`${routes.communityPool.getElementKey()}-manageSubjects`}
								path={`${routes.communityPool.getPath()}/manageSubjects`}
								component={CommunityPoolManageSubjects}
							/>
							<AuthRoute
								exact
								key={routes.reports.getElementKey()}
								path={routes.reports.getPath()}
								component={Reports}
							/>
							<AuthRoute
								exact
								key={`${routes.lesson.getElementKey()}-details`}
								path={`${routes.lesson.getPath()}/:lessonId`}
								component={LessonDetails}
							/>
							<AuthRoute
								exact
								key={`${routes.coupons.getElementKey()}`}
								path={`${routes.coupons.getPath()}`}
								component={Coupons}
							/>
							<AuthRoute
								exact
								key={`${routes.banners.getElementKey()}`}
								path={`${routes.banners.getPath()}`}
								component={BusinessBannersList}
							/>
							<AuthRoute
								exact
								key={`${routes.notifications.getElementKey()}`}
								path={`${routes.notifications.getPath()}`}
								component={ManageNotifications}
							/>
							<AuthRoute
								exact
								key={`${routes.discussionBoard.getElementKey()}`}
								path={`${routes.discussionBoard.getPath()}`}
								component={DiscussionBoard}
							/>
							<AuthRoute
								exact
								key={`${routes.videos.getElementKey()}`}
								path={`${routes.videos.getPath()}`}
								component={Videos}
							/>
							<AuthRoute
								exact
								key={`${routes.videos.getElementKey()}-editVideo`}
								path={`${routes.videos.getPath()}/:videoId`}
								component={EditVideo}
							/>
							<AuthRoute exact key={routes.books.getElementKey()} path={routes.books.getPath()} component={Books} />
							<AuthRoute exact key={routes.gifts.getElementKey()} path={routes.gifts.getPath()} component={Gifts} />
							<AuthRoute
								exact
								key={`${routes.gifts.getElementKey()}/add`}
								path={`${routes.gifts.getPath()}/add`}
								component={AddEditGift}
							/>
							<AuthRoute
								exact
								key={`${routes.gifts.getElementKey()}/edit`}
								path={`${routes.gifts.getPath()}/edit/:id`}
								component={AddEditGift}
							/>
							<AuthRoute
								exact
								key={routes.manageAdmins.getElementKey()}
								path={routes.manageAdmins.getPath()}
								component={ManageAdmins}
							/>
							<AuthRoute exact key={routes.tags.getElementKey()} path={routes.tags.getPath()} component={Tags} />
							<AuthRoute
								exact
								key={routes.stream.getElementKey()}
								path={routes.stream.getPath()}
								component={StreamHistory}
							/>
							<AuthRoute
								exact
								key={routes.stream.createNew.getElementKey()}
								path={routes.stream.createNew.getPath()}
								component={StartStream}
							/>
							<AuthRoute
								exact
								key={routes.stream.join.getElementKey()}
								path={`${routes.stream.join.getPath()}/:id/:sId`}
								component={StreamVideo}
							/>
						</Switch>
						<Notifications />
					</BaseLayout>
				</Switch>
			</Route>
		</BrowserRouter>
	);
};

export default Routes;
